<template>
  <p class='dc-control--error multiline' v-if='messages'>
    <span v-if='title'>{{title}}</span>
    <template v-for='(msg, index) in error_messages'>
      <span v-if='index > 0'>&nbsp;</span>
      <span>{{ msg }}</span>
    </template>
  </p>
</template>

<script>
  module.exports = {
    props: {
      messages: {
        type: [Array, String]
      },
      title: {
        type: String
      }
    },
    data() {
      return {
        error_messages: []
      };
    },
    watch: {
      messages: function(data) {
        if (typeof data === 'string' || data instanceof String) {
          this.error_messages = [data];
        } else {
          this.error_messages = data;
        }
      }
    }
  }
</script>
