import * as Spinner from 'shared/old_design/loading_spinner';

// refresh page in case of history back to prevent form autofill issues
export function SubscribePageReloader() {
  if ('onpageshow' in window) {
    window.onpageshow = function (e) {
      if (typeof window.performance != 'undefined' &&
        window.performance.navigation.type === 2) { // 2 means 'TYPE_BACK_FORWARD'
        setTimeout(()=>{
            window.scrollTo(0, 0);
         }, 100);

        Spinner.ShowLoadingSpinner();

        setTimeout(()=>{
          window.location.reload();
        }, 300);
    }};
  }
}
