let COPECART_LOADING_SPINNER_ID = "#copecart_loading_spinner";

export function HideLoadingSpinner() {
    $(COPECART_LOADING_SPINNER_ID).animate({
        opacity: 0,
    }, 250, function () {
        $(COPECART_LOADING_SPINNER_ID).hide();
    });
}

export function ShowLoadingSpinner() {
    $(COPECART_LOADING_SPINNER_ID).show().animate({
        opacity: 1,
    }, 250, function () {});
}
