import * as constants from 'shared/constants';

// Disables "invoice" payment method when there are any recurring payments
// and sets the default paymentMethod if is not set.
export function preselectPaymentMethod(scope){
  // Disable invoice when it was computed not to be available
  scope.paymentMethods.invoice = scope.isInvoiceAvailable

  if (scope.paymentMethod) {
    return
  }

  if (scope.paymentMethods.stripe) {
    scope.paymentMethod = constants.STRIPE

    return
  }

  for (const [paymentMethod, isAvailable] of Object.entries(scope.paymentMethods)) {
    if (isAvailable) {
      scope.paymentMethod = paymentMethod

      return
    }
  }
}
