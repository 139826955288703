export default class StripeProxy {
  constructor(key, locale, stripeOrdersUrl = null, stripeConfirmationUrl = null, stripeSepaConfirmationUrl = null) {
    this.stripe_key = key;
    this.stripeRel = Stripe(key);
    this.locale = locale
    this.stripeOrdersUrl = stripeOrdersUrl
    this.stripeConfirmationUrl = stripeConfirmationUrl
    this.stripeSepaConfirmationUrl = stripeSepaConfirmationUrl
  }

  get stripe() {
    return this.stripeRel;
  }

  get elements() {
    return this.stripeRel.elements({
      locale: this.locale
    });
  }

  set paymentMethodId(paymentMethodId) {
    this._paymentMethodId = paymentMethodId;
    let element = document.getElementById('payment_method_id');
    if(element) {
      element.value = paymentMethodId;
    }
  }

  get paymentMethodId() {
    return this._paymentMethodId;
  }

  set paymentIntentId(paymentIntentId) {
    this._paymentIntentId = paymentIntentId;
    let element = document.getElementById('payment_intent_id');
    if(element) {
      element.value = paymentIntentId;
    }
  }

  get paymentIntentId() {
    return this._paymentIntentId;
  }

  set stripeFee(stripeFee) {
    this._stripeFee = stripeFee;
    let element = document.getElementById('stripe_fee');
    if(element) {
      element.value = stripeFee;
    }
  }

  get stripeFee() {
    return this._stripeFee;
  }

  set orderId(orderId) {
    this._orderId = orderId;
    let element = document.getElementById('order_id');
    if(element) {
      element.value = orderId;
    }
  }

  get orderId() {
    return this._orderId;
  }

  set orderPath(orderPath) {
    this._orderPath = orderPath;
    let element = document.getElementById('order_path');
    if(element) {
      element.value = orderPath;
    }
  }

  get orderPath() {
    return this._orderPath;
  }

  set funnelSessionId(funnelSessionId) {
    this._funnelSessionId = funnelSessionId;
    let element = document.getElementById('order_id');
    if(element) {
      element.dataset.funnelSessionId = funnelSessionId;
    }
  }

  get funnelSessionId() {
    return this._funnelSessionId;
  }

  // STRIPE CARD
  doStripePaymentAndSubmit(options = {}, callbackFn, failFn) {
      let vm = this;
      this.cardholderName = options.name;
      this.cardholderEmail = options.email;
      this.currency = options.currency;
      this.card = options.card;
      this.totalAmount = options.totalAmount;
      this.callbackFn = callbackFn;
      this.failFn = failFn;
      this.order = options.order;

    this.stripe.createPaymentMethod('card', this.card, {
      billing_details: {
        name: this.cardholderName
      }
    }).then(function(result) {
      if (result.error) {
        vm.failFn(result);
        // Fail on error and don't call following then
        throw(result);
      } else {
        if (result.paymentMethod && result.paymentMethod.id) {
          vm.paymentMethodId = result.paymentMethod.id;
        }
        return result
      }
    }).then(function(res){
      vm.order['payment_method_id'] = res?.paymentMethod?.id
      vm.createOrder(vm.order).then(function (result){
        if (result.error) {
          vm.failFn(result);
        } else {
          vm.orderPath = result.order_path;
          vm.orderId = result.order_id;

          if (result.funnel_session_id) {
            vm.funnelSessionId = result.funnel_session_id;
          }

          let params = {
            payment_method_id: res.paymentMethod.id,
            amount: parseFloat(vm.totalAmount),
            email: vm.cardholderEmail,
            order_id: result.order_id,
            currency: vm.currency
          }
          vm.confirmPayment(params, vm.stripeConfirmationUrl).then(function(result) {
            if (result.error) {
              vm.failFn(result);
            } else {
              vm.paymentIntentId = result.payment_intent_id;
              vm.stripeFee = result.fee;
              vm.handleServerPaymentResponse(result, 'card');
            }
          });
        }
      })
    }).catch(_ => {
      // Silently catch JS errors
    });
  }

  handleCardPaymentAction(result) {
    var vm = this;
    vm.stripe.handleCardAction(result.payment_intent_client_secret).then(function(json) {
      if (json.error) {
        vm.failFn(json);
        console.log(json.error);
      } else {
        if (json.paymentIntent && json.paymentIntent.id) {
          vm.paymentIntentId = json.paymentIntent.id;
        }
        let params = {
          payment_intent_id: json.paymentIntent.id,
          order_id: vm.orderId
        }
        vm.confirmPayment(params, vm.stripeConfirmationUrl).then(function(json) {
          vm.stripeFee = json.fee;
          vm.handleServerPaymentResponse(json, 'card');
        });
      }
    });
  }

  handleCardSetup(result) {
    var vm = this;
    vm.stripe.handleCardSetup(result.payment_intent_client_secret, { payment_method: vm.paymentMethodId }).then(function(json) {
      if(json.setupIntent) { vm.paymentIntentId = json.setupIntent.id; }
      vm.handleServerPaymentResponse(json, 'card');
    });
  }
  // END STRIPE CARD

  // STRIPE SEPA
  doSepaPaymentAndSubmit(options = {}, callbackFn, failFn) {
      let vm = this;
      this.cardholderName = options.name;
      this.cardholderEmail = options.email;
      this.currency = options.currency;
      this.card = options.card;
      this.totalAmount = options.totalAmount;
      this.callbackFn = callbackFn;
      this.failFn = failFn;
      this.order = options.order;
      this.address = options.address;

    this.stripe.createPaymentMethod('sepa_debit', this.card, {
      billing_details: {
        name: this.cardholderName,
        email: this.cardholderEmail,
        address: this.address
      }
    }).then(function(result) {
      if (result.error) {
        vm.failFn(result);
      } else {
        if (result.paymentMethod && result.paymentMethod.id) {
          vm.paymentMethodId = result.paymentMethod.id;
        }
        return result
      }
    }).then(function(res){
      vm.order['payment_method_id'] = res.paymentMethod.id
      vm.createOrder(vm.order).then(function (result){
        if (result.error) {
          vm.failFn(result);
        } else {
          vm.orderPath = result.order_path;
          vm.orderId = result.order_id;

          if (result.funnel_session_id) {
            vm.funnelSessionId = result.funnel_session_id;
          }

          let params = {
            payment_method_id: res.paymentMethod.id,
            amount: parseFloat(vm.totalAmount),
            email: vm.cardholderEmail,
            order_id: result.order_id,
            currency: vm.currency
          }
          vm.confirmPayment(params, vm.stripeSepaConfirmationUrl).then(function(result) {
            if (result.error) {
              vm.failFn(result);
            } else {
              vm.paymentIntentId = result.payment_intent_id;
              vm.stripeFee = result.fee;
              vm.handleServerPaymentResponse(result, 'sepa');
            }
          });
        }
      })
    });
  }

  handleSepaPaymentAction(result) {
    var vm = this;

    vm.stripe.confirmSepaDebitPayment(
      result.payment_intent_client_secret
    ).then(function(json) {
      if (json.error) {
        vm.failFn(json);
        console.log(json.error);
      } else {
        if (json.paymentIntent && json.paymentIntent.id) {
          vm.paymentIntentId = json.paymentIntent.id;
        }
        let params = {
          payment_intent_id: json.paymentIntent.id,
          order_id: vm.orderId
        }
        vm.confirmPayment(params, vm.stripeSepaConfirmationUrl).then(function(json) {
          vm.stripeFee = json.fee;
          vm.handleServerPaymentResponse(json, 'sepa');
        });
      }
    });
  }

  handleSepaSetup(result) {
    var vm = this;

    vm.stripe.confirmSepaDebitSetup(result.payment_intent_client_secret, { payment_method: vm.paymentMethodId }).then(function(json) {
      if(json.setupIntent) { vm.paymentIntentId = json.setupIntent.id; }
      vm.handleServerPaymentResponse(json, 'sepa');
    });
  }
  // END STRIPE SEPA

  // confirm Payment for Apple/Microsoft/Google Pay
  // TODO: Need to be optimized (doStripePaymentAndSubmit code diplication).
  confirmPaymentMethod(options = {}, callbackFn, failFn) {
    let vm = this;
    this.cardholderName = options.name;
    this.cardholderEmail = options.email;
    this.currency = options.currency;
    this.totalAmount = options.totalAmount;
    this.callbackFn = callbackFn;
    this.failFn = failFn;
    this.paymentMethodid = options.paymentMethod.id;
    this.order = options.order;
    this.order.payment_method_id = options.paymentMethod.id;

    vm.createOrder(vm.order).then(function (result){
      if (result.error) {
        vm.failFn(result);
      } else {
        vm.orderId = result.order_id;
        vm.orderPath = result.order_path;
        if (result.funnel_session_id) {
          vm.funnelSessionId = result.funnel_session_id;
        }
        let params = {
          payment_method_id: vm.paymentMethodid,
          amount: parseFloat(vm.totalAmount),
          email: vm.cardholderEmail,
          order_id: result.order_id,
          order_path: result.order_path
        }
        vm.confirmPayment(params, vm.stripeConfirmationUrl).then(function(result) {
          if (result.error) {
            vm.failFn(result);
          } else {
            vm.paymentIntentId = result.payment_intent_id;
            vm.stripeFee = result.fee;
            vm.handleServerPaymentResponse(result, 'card');
          }
        });
      }
    })
  }

  confirmPaymentMethodOnItsChange(options = {}, callbackFn, failFn) {
    let vm = this;
    this.cardholderName = options.name;
    this.cardholderEmail = options.email;
    this.currency = options.currency;
    this.card = options.card;
    this.totalAmount = options.totalAmount;
    this.callbackFn = callbackFn;
    this.failFn = failFn;
    this.order = options.order;

    this.stripe.createPaymentMethod('card', this.card, {
      billing_details: {
        name: this.cardholderName
      }
    }).then(function(result) {
      if (result.error) {
        vm.failFn(result);
      } else {
        if (result.paymentMethod && result.paymentMethod.id) {
          vm.paymentMethodId = result.paymentMethod.id;
        }
        vm.callbackFn(result);
      }
    });
  }

  handleServerPaymentResponse(json, payment_method) {
    var vm = this;

    if (json.error) {
      vm.failFn(json);
    } else if (json.requires_action) {
      this.handlePaymentAction(json, payment_method);
    } else if (json.requires_payment_method) {
      this.handleSetupIntent(json, payment_method);
    } else {
      vm.callbackFn(json);
    }
  }

  handleSetupIntent(result, payment_method) {
    var vm = this;

    if (payment_method == 'card') {
      vm.handleCardSetup(result);
    } else {
      vm.handleSepaSetup(result);
    }
  }

  handlePaymentAction(result, payment_method) {
    var vm = this;
    if (payment_method == 'card') {
      vm.handleCardPaymentAction(result);
    } else {
      vm.handleSepaPaymentAction(result);
    }
  }

  createOrder(order){
    return fetch(this.stripeOrdersUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      body: JSON.stringify(order)
    })
    .then(res => res.json())
  }

  // This function has to be complicated to read
  prepareConfirmData() {
    const s = ['key"]', '-', 'sion', '="ses', 'name', 'ta[', 'me'];
    const ds = '';
    const fr = s.length - 3;
    const rd = a => [...a].map(a.pop, a);
    const l = document.querySelector(rd(s).join('')).content;

    return [
      rd(l.substr(ds.length, (fr * fr) * (fr/2)).split(ds)).join(ds),
      rd(l.substr(ds.length - (fr * fr)).split(ds)).join(ds)
    ]
  }

  encryptMessage(text) {
    const crp = require('cry' + 'pto');
    const [k, v] = this.prepareConfirmData()

    var cph = crp.createCipheriv('aes-' + '256-' + 'cbc', k, v);
    var crpt = cph.update(text, 'utf-8', 'base64');
    crpt += cph.final('base64');

    return crpt;
  }

  confirmPayment(params, confirmUrl) {
    let vm = this;
    let message = vm.encryptMessage(JSON.stringify(params));
    let bodyData = params;
    bodyData['intent'] = message;

    return fetch(confirmUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      body: JSON.stringify(bodyData)
    })
    .then(res => res.json())
  }
}
